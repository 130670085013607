import React, { FunctionComponent } from 'react';
import { makeStyles } from '@admitkard/ccl/style';
import { cn } from '@admitkard/ccl';

interface SuaveButtonProps {
  style?: React.CSSProperties | undefined,
  className?: string | undefined,
}

const useStyles = makeStyles(() => ({
  root: {
    width: 'fit-content',
    padding: '0.75rem 0.75rem 0.75rem 1.25rem',
    background: '#483287',
    borderRadius: '2.1875rem',
    display: 'flex',
    alignItems: 'baseline',
    gap: '0.75rem',
    '& span': {
      fontFamily: 'Poppins',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
      color: '#F7EFFF',
    },
    '@media screen and (min-width: 992px)': {
      padding: '1rem 0.75rem 1rem 1.25rem',
      '& span': {
        fontSize: '1rem',
      },
    }
  },
  iconContainer: {
    background: '#F7EFFF',
    borderRadius: '2.0041rem',
    width: '1.25rem',
    height: '1.25rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      height: '0.5625rem',
      width: '0.375rem',
    },
    '@media screen and (min-width: 992px)': {
      width: '1.5rem',
      height: '1.5rem',
      '& svg': {
        height: '0.5rem',
        width: '0.3125rem',
      },
    }
  }
}), {name: 'SuaveButton'});

const SuaveButton: FunctionComponent<SuaveButtonProps> = (props) => {
  const classes = useStyles();
  return (
    <div style={{...props.style}} className={cn(props.className,classes.root)} role='button'  >
      <span>{props.children}</span>
      <div className={classes.iconContainer} >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 6 9">
          <path
            fill="#483287"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.84 4.62a.2.2 0 0 0 0-.28L.7 1.19a.5.5 0 0 1 .7-.71l3.65 3.65c.2.2.2.5 0 .7L1.4 8.48a.5.5 0 1 1-.7-.7l3.14-3.16Z"
          />
        </svg>
      </div>
    </div>
  );
};

export default SuaveButton;
