/* eslint-disable max-len */
import { makeStyles } from '@admitkard/ccl/style';
import React from 'react';
import Head from 'next/head';
import { HeaderLabel } from '../CommonComponents';
import {faqArray} from './Constant';
import FAQItem from './FAQItem';
import {generateFAQSchema} from '@admitkard/uiutils/web_schemas';

const useStyles = makeStyles(
  (theme) => ({
    rootContainer: {
      width: '100%',
      boxSizing: 'border-box',
      fontFamily: 'Poppins, sans-serif !important',
      color: theme.colorPalette.indigo.main,
    },
    faqContainer: {
      margin: '0 1rem',
      display: 'flex',
      flexDirection: 'column',
      gap: '0.25rem',
      '@media screen and (min-width: 1200px)': {
        margin: '0',
        gap: '0.5rem',
      },
    },
  }),
  { name: 'FAQSection' }
);

const FAQSection: React.FunctionComponent = () => {
  const classes = useStyles();
  const faqSchema = generateFAQSchema(faqArray);

  return (
    <div className={classes.rootContainer}>
      <Head>
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(faqSchema),
          }}
        />
      </Head>
      <HeaderLabel label="FAQ" />
      <div className={classes.faqContainer}>
        {faqArray
          .filter((faq) => faq.active)
          .map((faq, index) => (
            <FAQItem key={index} title={faq.title} content={faq.content} />
          ))}
      </div>
    </div>
  );
};

export default FAQSection;
