import React, { FunctionComponent } from 'react';
import { makeStyles } from '@admitkard/ccl/style';

interface HeaderLabelProps {
  label: string;
  highlightText?: string,
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontFamily: 'Poppins',
    fontSize: '1.25rem',
    fontWeight: 400,
    color: theme.colorPalette.indigo.main,
    lineHeight: '1.75rem',
    margin: '0 2rem',
    width: '14.75rem',
    marginBottom: '1rem',
    backgroundColor: '#FFFFFF',
    '& span': {
      color: theme.colorPalette.purple.main,
    },
    '@media screen and (min-width: 992px)': {
      margin: '0',
      marginBottom: '2.25rem',
      fontSize: '2rem',
      lineHeight: '2.625rem',
      width: '23.3125rem',
    },
  }
}), {name: 'HeaderLabel'});

export const HeaderLabel: FunctionComponent<HeaderLabelProps> = (props) => {
  const classes = useStyles();
  return <h1 className={classes.label} >{props.label} {props.highlightText && <span>{`${props.highlightText} `}</span>} </h1>;
};

export default HeaderLabel;
