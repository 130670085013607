import { cn } from '@admitkard/ccl';
import { makeStyles } from '@admitkard/ccl/style';
import { ArrowSVG } from '../../SvgCollection';
import React, { useState } from 'react';

interface FAQItemProps {
  title: string;
  content: string;
}

const useStyles = makeStyles(
  () => ({
    rootContainer: {
      borderRadius: '1rem',
      backgroundColor: 'hsla(267, 100%, 98%, 1)',
      padding: '1rem 1.25rem',
      boxSizing: 'border-box',
      '@media screen and (min-width: 1200px)': {
        padding: '1.5rem 1.5rem',
      },
    },
    titleContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: '1.5rem',
      width: '100%',
      margin:'0',
      '@media screen and (min-width: 1200px)': {
        fontSize: '1.25rem',
      },
    },
    content: {
      transition: 'max-height 400ms ease-in',
      fontWeight: 400,
      fontSize: '0.75rem',
      width: '100%',
      overflow: 'hidden',
      maxHeight: '0',
      '@media screen and (min-width: 1200px)': {
        fontSize: '1rem',
        lineHeight: '184%',
      },
    },
    contentExpand: {
      maxHeight: '100vh',
    },
    expandingIcon: {
      width: '0.8rem',
      height: '0.8rem',
      display: 'flex',
      marginTop: '0.5rem',
      transition: 'transform 600ms ease-in',
      transform: 'rotate(90deg)',
    },
    expandingIconExpanded: {
      marginTop: '0',
      transform: 'rotate(270deg)',
    },
  }),
  { name: 'FAQItem' }
);

const FAQItem: React.FunctionComponent<FAQItemProps> = (props) => {
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={classes.rootContainer} onClick={() => setIsExpanded(!isExpanded)}>
      <div className={classes.titleContainer}>
        <h2 className={classes.title}>{props.title}</h2>
        <div
          className={cn(classes.expandingIcon, {
            [classes.expandingIconExpanded]: isExpanded,
          })}
        >
          {ArrowSVG}
        </div>
      </div>
      <div
        className={cn(classes.content, {
          [classes.contentExpand]: isExpanded,
        })}
      >
        <div className="v-space" style={{ height: '1rem' }} />
        {props.content}
      </div>
    </div>
  );
};

export default FAQItem;
