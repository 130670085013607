/* eslint-disable max-len */

export const faqArray = [
  {
    title: 'What is the vision and mission of AdmitKard?',
    content: "AdmitKard's vision is to empower students with the right knowledge, guidance and support to make informed decisions about their education and career, and to become successful global citizens. Its mission is to create a seamless and personalized experience for students seeking to study abroad by leveraging technology, data, and human expertise.",
    active: true
  },
  {
    title: 'How does AdmitKard help me?',
    content: "AdmitKard provides a range of services to help students build a strong profile for study abroad, including personalized guidance on academic and extracurricular activities, writing a statement of purpose (SOP), creating a resume, and preparing for interviews. AdmitKard also has a team of expert editors who can review and provide feedback on students' application materials.",
    active: true
  },
  {
    title: 'How does AdmitKard help with profile building for studying abroad?',
    content: 'At AdmitKard, we understand that each one of you is unique. With ProfileKard, we help students build an impactful profile for studying abroad by providing personalized evaluation, expert guidance, and profile enhancement by helping you engage in meaningful activities that significantly increase the chances of getting accepted into the desired universities.',
    active: true
  },
  {
    title: "Are AdmitKard's services free or paid?",
    content: 'AdmitKard offers both free and paid services to students. However, some of its more specialized services, such as platinum admissions and ProfileKard, are paid.',
    active: true
  },
  {
    title: 'Does AdmitKard help with scholarship applications for study abroad?',
    content: "Yes, AdmitKard provides guidance and support with scholarship applications for study abroad. AdmitKard's experts can help students identify relevant scholarships, both internal and external, understand eligibility criteria, and assist with application submission.",
    active: true
  },
  {
    title: 'Can AdmitKard help me with securing an Education Loan?',
    content: 'Yes, AdmitKard has a fintech vertical that can help students with the financial aspects of studying abroad, including applying for education loans. AdmitKard works with various banks and financial institutions to provide the best loan options for its students.',
    active: true
  },
  {
    title: 'Who are the Mentors at AdmitKard?',
    content: 'AdmitKard Mentors are Indian students who are already studying at the target universities and pursuing your target course. They might be studying in your Target Country, Target University and pursuing your target course as well. They can guide you through all the nitty-gritties.',
    active: false
  },
  {
    title: 'What is MentorX at AdmitKard?',
    content: "MentorX is AdmitKard's is an ensemble of study abroad products, utilities & services where a student can get everything starting from flight tickets, bank account, credit cards, and sim cards to accommodation. The MentorX services are exclusively FREE and can be availed by any student after their VISA is granted. If a student avails all the services they get a total of $500 in rewards and cashback.",
    active: false
  },
  {
    title: 'Does AdmitKard offer test preparation services?',
    content: "Yes, AdmitKard has knowledge partners who are top-notch trainers and offers test preparation services for various exams students may need to take for study abroad, such as the GRE, GMAT, Digital SAT, ACT, APs, TOEFL, and IELTS. These services include group/1-1 coaching, practice tests, and study materials. AdmitKard's experts can also provide guidance on which exams to take and how to prepare for them.",
    active: false
  },
  {
    title: 'What countries and universities does AdmitKard cover for study abroad?',
    content: "AdmitKard covers a wide range of countries and universities for study abroad, including the USA, UK, Canada, Australia, New Zealand, Ireland, Germany, France, Singapore, and more. AdmitKard's experts can provide guidance on which countries and universities may be the best fit for a student's academic and career goals.",
    active: false
  },
  {
    title: 'How long does the study abroad process take with AdmitKard?',
    content: "The study abroad process can vary in length depending on the individual student's situation and goals. However, AdmitKard typically recommends that students start the process at least 12-18 months before the start of their desired academic term. This allows sufficient time for research, test preparation, application submission, and visa processing.",
    active: false
  },
  {
    title: 'Will AdmitKard help me get my Student Visa?',
    content: "AdmitKard provides guidance and support throughout the visa application process, including help with documentation, visa application forms, and scheduling appointments. AdmitKard's Visa experts can also provide advice on how to prepare for visa interviews and what to expect during the visa application process.",
    active: false
  },
  {
    title: 'How does AdmitKard ensure the confidentiality and privacy of student information?',
    content: 'AdmitKard takes the confidentiality and privacy of student information very seriously and has strict policies and protocols in place to ensure data security. AdmitKard uses secure servers and encryption technologies to protect student data, and only authorized personnel have access to this information.',
    active: false
  },
];

